import React, { useState } from 'react';
import './Contact.scss';

import { IonPage, IonContent, IonInput, IonItem, IonLabel, IonButton, IonItemGroup,
         IonModal, IonItemDivider, IonSelect, IonSelectOption,
         IonGrid, IonCol, IonRow } from '@ionic/react';

const Contact: React.FC = () => {

  const [ name, setName ] = useState<any>();
  const [ email, setEmail ] = useState<any>();
  const [ phone, setPhone ] = useState<any>();
  const [ message, setMessage ] = useState<any>();

  const [ formErrors, setFormErrors ] = useState<any>({
     message: ""
  });

 const [showModal, setShowModal] = useState(false);

  const submit = async () => {
    try {
      //console.log('api needed');
      //console.log(name);
      
     // await login({
      //  email,
      //  password
      //});
    
    } catch (e) {
      setFormErrors(e);
    }
  }
  
  return (
    <IonPage>
        <IonContent>
         <IonItemGroup class="form-border-all">
              <IonGrid>
              <IonRow class="ion-justify-content-center">
                <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">

                <IonRow>
                    <IonCol>
                      <IonItem class="no-border">
                        <IonLabel class="ion-label-contact" position="stacked">Reach us by email:</IonLabel><span className='contact-details'>sales@centripet.com</span>
                       </IonItem>
                    </IonCol>
                    <IonCol>
                    <IonItem class="no-border">
                        <IonLabel class="ion-label-contact" position="stacked">Reach us by phone:</IonLabel><span className='contact-details'>(314)-262-9559</span>
                       </IonItem>
                       
                  </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol>
                    <IonItem class="no-border">
                        <p className='contact-details'>24/7 Service</p>
                       </IonItem>
                    </IonCol>
                </IonRow>
                
                </IonCol>
                </IonRow>
               </IonGrid>
          </IonItemGroup>
      </IonContent>
    </IonPage>
  );

};

export default Contact;