import axios from 'axios';

//fetch data from node js express
  export const fetchDataTest = async () => {

    const promise  = axios.get("/api/v3/reactdataparse",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then((response:any) => response.data)
  
    return dataPromise;

  } 

  //fetch data from spring
  export const fetchServiceContent = async () => {

    const promise  = axios.get("/cnt/servicesContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.ServiceContent
    )
    return dataPromise;
  
  } 