import React from 'react';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonButtons,
  IonMenuButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonCard,
} from '@ionic/react';
import { menuController } from '@ionic/core';
import { menuOutline } from 'ionicons/icons';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router';
import CentripetHome from '../CentripetHome/Home';
import Sectors from '../Sectors/Sectors';
import Services from '../Services/Services';
import ServiceDetails from '../ServiceDetails/ServiceDetails';
import Contact from '../Contact/Contact';
import About from '../About/About';
import Sitemap from '../Sitemap/Sitemap';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from '../TermsOfUse/TermsOfUse';
import './MenuMobile.scss';

const MenuMobile: React.FC = () => {
  const onClickHandler = (): void => {
    menuController.close();
  };

  const MainImageTopmobile = () => {
    return (
      <>
      <div className="root-mobile">
      <img src='assets/icon/icon_main.png' className="main-icon-mobile" />
      </div>
      <div className="main-icon-mobile">
        <p>Keeping you connected</p>
      </div>
      </>
    );
  };

  return (
    <>
    <IonMenu contentId="main-content"
      side="start"
      menuId="first"
      swipe-gesture={true}
      disabled={false}
      maxEdgeStart={100}
      hidden={false}
      type="overlay"
      >
          <IonHeader translucent>
            <IonToolbar color="primary">
              <IonTitle>Centripet
              <IonIcon slot="start" icon={menuOutline}></IonIcon>
              </IonTitle>
              <IonButtons slot="start">
                <IonMenuButton
                  autoHide={true}
                  onClick={() => onClickHandler()}
                ></IonMenuButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding no-scroll"><IonCard className='card'>Software & IT services company to keep you on target</IonCard>
          <IonList>
            <IonItem routerLink="/centripetHome">
              <IonLabel>Home</IonLabel>
            </IonItem>
            <IonItem routerLink="/sectors">
              <IonLabel>Sectors</IonLabel>
            </IonItem>
            <IonItem routerLink="/services">
              <IonLabel>Services</IonLabel>
            </IonItem>
            <IonItem routerLink="/contact">
              <IonLabel>Contact</IonLabel>
            </IonItem>
            <IonItem routerLink="/about">
              <IonLabel>About</IonLabel>
            </IonItem>
          </IonList>
          </IonContent>
    </IonMenu>
      <IonContent class="no-scroll" id="main-content">
        <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton></IonMenuButton>
              </IonButtons>
              <IonCard className='card-top-mobile-header'>
                <img src='assets/icon/icon_main.png' className="main-icon-mobile" />
                <IonTitle className='mainMessage-icon-mobile'>Centripet</IonTitle>
                </IonCard>
            </IonToolbar>
          </IonHeader>
          
          <IonContent>
        <IonReactRouter>
            <IonRouterOutlet class="inner-page">
              <Route path="/centripetHome" component={CentripetHome} exact={true} />
              <Route path="/sectors" component={Sectors} exact={true} />
              <Route path="/services" component={Services} exact={true} />
              <Route path="/serviceDetails" component={ServiceDetails} exact={true} />
              <Route path="/contact" component={Contact} />
              <Route path="/about" component={About} />
              <Route path="/sitemap" component={Sitemap} />
              <Route path="/privacyPolicy" component={PrivacyPolicy} />
              <Route path="/termsOfUse" component={TermsOfUse} />
              <Route path="/" render={() => <Redirect to="/centripetHome" />} exact={true} />
            </IonRouterOutlet>
        </IonReactRouter>
      </IonContent>

  </IonContent>
    </>
  );
};

export default MenuMobile;