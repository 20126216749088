import { IonCol, IonContent, IonGrid, IonItem, IonItemGroup, IonLabel, IonList, IonListHeader, IonPage, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import './PrivacyPolicy.scss';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

const PrivacyPolicy: React.FC = () => {

const [privacyPolicyHtlm, setPrivacyPolicyHtlm] = useState("") as any;

const generatePrivacyPolicyHtml = () => {
 
  return (
      <>
    <IonRow>
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
        <IonLabel class="top-list-header-label">Privacy Policy</IonLabel>
    </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
        <IonList lines="none">
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              We strive to maintain the accuracy, confidentiality, and security of your personally identifiable information ("Personal Information").
              As part of this commitment, our privacy policy governs our actions as they relate to the collection, use and disclosure of Personal Information.
            </p>
            </IonItem>
            </IonList>
    </IonItem>
    </IonRow>   
    
    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
        <IonList lines="none">
          <IonListHeader>
            <IonLabel class="list-header-label">1. Introduction</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
               We take the responsibility for maintaining and protecting the Personal Information under our control. We have designated an individual or individuals who
               is/are responsible for compliance with our privacy policy.
            </p>
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">2. Identifying Purposes</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
              <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
                We collect, use and disclose Personal Information to provide you with the product or service you have requested and to offer you additional
                products and services we believe you might be interested in. The purposes for which we collect Personal Information will be identified before
                or at the time we collect the information. In certain circumstances, the purposes for which information is collected may be clear, and consent
                may be implied, such as where your name, address and payment information is provided as part of the order process.
              </p>
            </IonItem> 
        </IonList>
      </IonItem>
    </IonRow>  

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">3. User's Consent </IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law. Providing
              us with your Personal Information is always your choice. However, your decision not to provide certain information may limit our ability to provide you
              with our products or services. We will not require you to consent to the collection, use, or disclosure of information as a condition to the supply of a
              product or service, except as required to be able to supply the product or service.
             </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>  

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">4. Limiting Collection</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              The Personal Information collected will be limited to those details necessary for the purposes identified by us. With your consent, we may collect
              Personal Information from you in person, over the telephone or by corresponding with you via mail, facsimile, or the Internet.
             </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">5. Limiting Use, Disclosure and Retention</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Personal Information may only be used or disclosed for the purpose for which it was collected unless you have otherwise consented, or when it is required
              or permitted by law. Personal Information will only be retained for the period of time required to fulfill the purpose for which we collected it or as may be required by law.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">6. Accuracy</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Personal Information will be maintained in as accurate, complete and up-to-date form as is necessary to fulfill the purposes for which it is to be used.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">7. Protecting Customer Information</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information. We take all reasonable
              precautions to protect your Personal Information from any loss or unauthorized use, access or disclosure.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">8. Availability</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              We will make information available to you about our policies and practices with respect to the management of your Personal Information.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">9. Access</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Upon request, you will be informed of the existence, use and disclosure of your Personal Information, and will be given access to it.
              You may verify the accuracy and completeness of your Personal Information, and may request that it be amended, if appropriate. However, in certain circumstances permitted by law, we will not disclose certain information to you. For example, we may not disclose information relating to you if other individuals are referenced or if there are legal, security or commercial proprietary restrictions.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">10. Customer Complaints and Suggestions </IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              You may direct any questions or enquiries with respect to our privacy policy or our practices by contacting:
            </p> 
            </IonItem>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              customer.support@centripet.com
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">Use of Cookies</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              A cookie is a small computer file or piece of information that may be stored in your computer's hard drive when you
              visit our websites. We may use cookies to improve our website’s functionality and in some cases, to provide visitors with a customized online experience.
            </p> 
            </IonItem>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Cookies are widely used and most web browsers are configured initially to accept cookies automatically. You may change your Internet browser settings
              to prevent your computer from accepting cookies or to notify you when you receive a cookie so that you may decline its acceptance. Please note, however,
              if you disable cookies, you may not experience optimal performance of our website.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    <IonRow class="row-margin">
      <IonItem class={(!isMobile) ? "no-border":"no-border no-border-mobile-privacy"}>
          <IonList lines="none">
          <IonListHeader>
              <IonLabel class="list-header-label">External Web Links</IonLabel>
          </IonListHeader>
            <IonItem class="no-underline">
            <p className={(!isMobile) ? "full-page":"full-page-mobile"}>
              Our website may contain links to other third party sites that are not governed by this privacy policy.
              Although we strive to only link to sites with high privacy standards, our privacy policy will no longer
              apply once you leave our website. Additionally, we are not responsible for the privacy practices employed by third party websites.
              Therefore, we suggest that you examine the privacy statements of those sites to learn how your information may be collected, used, shared and disclosed.
            </p> 
            </IonItem>
        </IonList>
      </IonItem>
    </IonRow>

    </>
       
  );
};

useEffect(() => {
  setPrivacyPolicyHtlm(generatePrivacyPolicyHtml());
}, []);

return (
  <IonPage>
  <IonContent>
   <IonItemGroup class="form-border-all">
      <IonGrid>
        <IonRow class="ion-justify-content-center">
          <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">
            {privacyPolicyHtlm}
          </IonCol>   
        </IonRow>    
      </IonGrid>
      </IonItemGroup>
      </IonContent>
      </IonPage>
 
);

};

export default PrivacyPolicy;