import React from 'react';
import { IonContent, IonPage, IonIcon, IonItemGroup, IonItem, IonList, IonListHeader, IonButton, IonGrid, IonCol, IonRow, IonHeader, IonToolbar, IonTitle, IonButtons, IonLabel } from '@ionic/react';
import { copy } from 'ionicons/icons';
import './ServiceDetails.scss';
import { Link, useHistory } from 'react-router-dom';
import {  shallowEqual, useSelector, RootStateOrAny } from 'react-redux';

const ServiceDetails: React.FC = (props: any) => {
const history = useHistory();
const myContent  = useSelector((state: RootStateOrAny) => state?.content); //retrive redux state from the store

const backClickHandler = (e:any,to:any)  => { 
  //console.log('so whats clicko ====');
  //console.log(to);
  e.preventDefault(); 
   history.push({
       pathname: to,
       state: '' 
  });
   
  return; 
};

const DetailsArea = (props: any) => {
  return (
       <>
      <IonHeader className="ion-no-border" translucent>
      <IonToolbar>
        <IonTitle>Service Details</IonTitle>
        <IonButtons slot="start">
          <IonButton class="back-button" fill="clear" onClick={(e) => backClickHandler(e,"/services")}>&lt;Back</IonButton>
        </IonButtons>
      </IonToolbar>
      </IonHeader>
      <IonLabel class="sub-title">{props.subTitle}</IonLabel><p className='service-details'>{props.mainDetail}</p>
      </>
    
  );
};

const findContentHeading = (itemKey:String) => {
  try{
    const result = myContent.find((content:any) => content?.mobilecontentkey == itemKey);
    return result?.heading;
  } catch (e:any) {
    console.log(e.message);
  }

}

const findMainContent = (itemKey:String) => {
  try{
    const result = myContent.find((content:any) => content?.mobilecontentkey == itemKey);
    return result?.content;
  } catch (e:any) {
    console.log(e.message);
  }

}

const { state } = props.location
//console.log('the state at details???');
//console.log(state);
  return (
    <IonPage>
      <IonContent>
        <IonItemGroup class="form-border-all">
          <IonGrid>
            <IonRow class="ion-justify-content-center">
              <IonCol size-xs="12" size-sm="9" size-md="10" size-lg="10" size-xl="9">
                {(state=='APInCloud') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={findContentHeading(state)} mainDetail={findMainContent(state)} />
                </IonRow> :
                (state=='DatabaseEng') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={findContentHeading(state)} mainDetail={findMainContent(state)} />
                </IonRow>:
                (state=='ScalingOpt') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={findContentHeading(state)} mainDetail={findMainContent(state)} />
                </IonRow> :
                (state=='SoftwareArcnDev') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={findContentHeading(state)} mainDetail={findMainContent(state)} />
                </IonRow> :
                (state=='DevopsnCICD') ? 
                <IonRow>
                  <DetailsArea state={state} subTitle={findContentHeading(state)} mainDetail={findMainContent(state)} />
                </IonRow> :
                <IonRow>
                  <p className='service-details'>Service Details</p>
                </IonRow>
                }
              </IonCol>
            </IonRow>  
          </IonGrid>       
        </IonItemGroup>
      </IonContent>
    </IonPage>
  );
};

export default ServiceDetails;
