import React,{ createContext, useState} from 'react';

  export const actionTypes = {
    SET_CONTENT: 'SET_CONTENT',
  }

  /*
  const initialState = {
    id: 0,
    heading: "",
    content: ""
  };
  */

  const initialState = [{
    id: 0,
    heading: "",
    content: ""
  }];
  

export const AppReducer = (state:any[]=initialState, action:any) => {
    switch (action.type) {
        //add an array of objects
        case 'addedOnce': {
          //console.log('did we make it to this reducer>>>>');
          //console.log('the action'+action.payload);
          const myobj: any[] = action.payload;
          //console.log('array of objects payload at reducer');
          //console.log(myobj);
          state=myobj;
          return state;
      } 
        case 'added': {
            //console.log('did we make it to this reducer>>>>');
           //console.log('the action'+action.payload);
            //console.log(Object.values(action.payload)[0]);
            //const data = JSON.stringify(action.payload).toString();
            const myobj: any = Object.entries(action.payload);
            //console.log(myobj[0][1]);
            //console.log(myobj[0][1].id);            
          return [...state, {
            id: myobj[0][1].id,
            heading: myobj[0][1].heading,
            content: myobj[0][1].content
          }];
        }
        case 'changed': {
          return state.map((t:any) => {
            if (t.id === action.id) {
              return action;
            } else {
              return t;
            }
          });
        }
        case 'deleted': {
          return state.filter((t:any) => t.id !== action.id);
        }
        default: {
            //console.log('is this default called???');
           return state;       
          //throw Error('Unknown action: ' + action.type);
        }
      }
  }